import {
    Component,
    Input,
    OnInit,
    ViewChild,
    OnChanges,
    SimpleChanges,
    ChangeDetectorRef,
    OnDestroy,
} from '@angular/core';
import { PageableDataTableComponent } from 'src/app/shared/components/pageable-data-table/pageable-data-table.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AkTransactionService } from 'src/app/modules/ak/pages/cash-accounts/services/ak-transaction.service';
import { BookingType } from 'src/app/core/models/enums/BookingType';
import { MatTableDataSource } from '@angular/material/table';
import { getDateString } from '../../functions/getDateString';
import { BookingTypeService } from 'src/app/modules/ak/pages/all-transactions/services/booking-type.service';
import { Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { isTimeDifferenceLessThanNMilliseconds } from 'src/app/shared/functions/isTimeDifferenceLessThanNMilliseconds';
import { AccountType } from 'src/app/core/models/enums/AccountType';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { TransactionStatus } from 'models';
import moment from 'moment';
import { LocaleService } from 'src/app/core/services/locale.service';
import { DateAdapter } from '@angular/material/core';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-cash-register-transactions-table',
    templateUrl: './cash-register-transactions-table.component.html',
    styleUrls: ['./cash-register-transactions-table.component.scss'],
})
export class CashRegisterTransactionsTableComponent
    extends PageableDataTableComponent
    implements OnInit, OnChanges, OnDestroy
{
    constructor(
        private akTransactionService: AkTransactionService,
        public bookingTypeService: BookingTypeService,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        public dialog: MatDialog,
        private translateService: TranslateService,
        private localeService: LocaleService,
        private dateAdapter: DateAdapter<Date>
    ) {
        super();
    }

    @Input() refreshData: any;
    @Input() myData: any;
    @Input() originator: any;

    @Output() canceledTransaction = new EventEmitter<any>();

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    selectedBookingType: any;
    selectedDateFrom: Date;
    selectedDateTo: Date;
    selectedAmountFrom: number;
    selectedAmountTo: number;
    selectedBookingId: string;
    selectedRemark: string;
    showCancelledBookings: boolean = true;
    bookingTypes: any[] = [];

    totalElements: number;
    pageNumber: number = 7;
    pageSize: number = 20;
    dataSource: MatTableDataSource<any>;
    pageEvent: PageEvent;

    paging: any = {};
    sorting: any = {};
    filtering: any = {};

    displayedColumns = [
        'createdAt',
        'valueDate',
        'bookingTypeId',
        'amount',
        'balance',
        'runningBalance',
        'remark',
        'pvClanBookingId',
        'transactionStatus',
        'cancelBooking',
    ];
    destroyed$ = new Subject();

    ngOnInit(): void {
        this.getFilterOptions();
        this.setInitialSorting();
        this.setInitialFiltering();
        this.getCashRegisterTransactions();

        this.localeService.$currentLocale.pipe(takeUntil(this.destroyed$)).subscribe((locale) => {
            this.dateAdapter.setLocale(locale);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.refreshData && changes.refreshData.currentValue) {
            this.refreshTable(this.refreshData);
        }
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    refreshTable(transaction: any) {
        this.fetchDataWithDelay(transaction);
    }

    setInitialFiltering() {
        if (this.router.url.includes('/cashRegister')) {
            this.filtering.accountType = AccountType.CASH_REGISTER;
        } else if (this.router.url.includes('/cashAccounts')) {
            this.filtering.accountType = AccountType.PERSONAL;
            this.filtering.personId = this.myData.personId;
            this.filtering.vsnum = this.myData.insurances[0]?.vsNum;
            this.paging.pageSize = 20;
        }
    }

    setInitialSorting() {
        this.sorting.sortColumn = 'createdAt';
        this.sorting.sortDirection = 'desc';
    }

    getFilterOptions() {
        Object.keys(BookingType).forEach((bt) => {
            this.bookingTypes.push({ name: bt, bookingTypeId: this.bookingTypeService.getBookingTypeByName(bt) });
        });

        this.bookingTypes.push({ name: 'All', bookingTypeId: '-1' });
    }

    onSort(event: MatSort) {
        // Retrieve the active column and sorting direction
        this.sorting.sortColumn = event.active;
        this.sorting.sortDirection = event.direction;

        // Make an API request with the sorting parameters
        this.getCashRegisterTransactions();
    }

    getServices(event?: PageEvent) {
        this.paging.pageIndex = event.pageIndex;
        this.paging.pageSize = event.pageSize;

        this.getCashRegisterTransactions();
        return event;
    }

    applyFilters() {
        if (this.selectedBookingType !== undefined) {
            this.filtering.bookingType = this.selectedBookingType;
        }

        if (this.selectedDateFrom !== undefined && this.selectedDateFrom !== null) {
            this.filtering.dateFrom = getDateString(this.selectedDateFrom);
        } else {
            this.filtering.dateFrom = null;
        }

        if (this.selectedDateTo !== undefined && this.selectedDateTo !== null) {
            this.filtering.dateTo = getDateString(this.selectedDateTo);
        } else {
            this.filtering.dateTo = null;
        }

        if (this.selectedAmountFrom !== undefined) {
            this.filtering.amountFrom = this.selectedAmountFrom;
        }

        if (this.selectedAmountTo !== undefined) {
            this.filtering.amountTo = this.selectedAmountTo;
        }

        if (this.selectedBookingId !== undefined) {
            this.filtering.bookingId = this.selectedBookingId;
        }

        if (this.selectedRemark !== undefined && this.selectedRemark !== null) {
            this.filtering.remark = this.selectedRemark;
        }

        this.getCashRegisterTransactions();
    }

    getCashRegisterTransactions() {
        this.akTransactionService
            .getTransactions(this.paging, this.sorting, this.filtering)
            .subscribe((transactions) => {
                this.setData(transactions);
            });
    }

    setData(transactions: any) {
        this.dataSource = new MatTableDataSource(transactions.content);
        this.totalElements = transactions.totalElements;
        this.pageSize = transactions.pageable.pageSize;
        this.pageNumber = transactions.pageable.pageNumber;
        this.dataSource.sort = this.sort;
        this.changeDetectorRef.detectChanges();
    }

    fetchDataWithDelay(transaction: any) {
        this.akTransactionService
            .getTransactions(this.paging, this.sorting, this.filtering)
            .subscribe((transactions) => {
                const recordExists = transactions.content.some((record) =>
                    isTimeDifferenceLessThanNMilliseconds(record.createdAt, transaction.timestamp, 15000)
                );

                if (recordExists) {
                    // Update your data source if the record exists
                    this.setData(transactions);
                } else {
                    // If the record doesn't exist, retry the request after a delay
                    setTimeout(() => {
                        this.fetchDataWithDelay(transaction);
                    }, 1000);
                }
            });
    }

    sortDirectionCheck(sort: MatSort) {
        return (sort.direction = sort.direction === '' ? 'asc' : sort.direction);
    }

    cancelTransaction(transaction) {
        if (!this.isCancellable(transaction)) return;

        let title = this.translateService.instant('ak.labels.cancellationTitle');
        let message = this.translateService
            .instant('ak.labels.cancellationMessage')
            .replace('{{bookingId}}', transaction.pvClanBookingId);
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '350px',
            data: {
                title: title,
                message: message,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.canceledTransaction.emit(transaction);
            }
        });
    }

    isCancellable(transaction: any) {
        if (!this.router.url.includes('/cashRegister')) {
            if (!this.myData.personalInformation.active) return false;
        }
        // transaction is cancellable if all of the following conditions are met:
        // - if status is DONE (not cancelled)
        // - if booking type id is even number (not storno type)
        // - if transaction is not older than today days

        if (transaction.transactionStatus === TransactionStatus.CANCELLED) return false;
        // if transaction is of one of the following types - it is only cancellable if it is submitted on current day after 12
        if (
            transaction.bookingType === BookingType.BankTransfer ||
            transaction.bookingType === BookingType.AccountClosing ||
            transaction.bookingType === BookingType.PensionFund
        ) {
            return this.isTransactionCreatedWithinTimeframe(transaction.createdAt);
        }

        return (
            transaction.transactionStatus === TransactionStatus.DONE &&
            transaction.bookingTypeId % 2 === 0 &&
            this.isTransactionCreatedOnCurrentDate(transaction.createdAt)
        );
    }

    isTransactionCreatedOnCurrentDate(transactionCreatedAt: string): boolean {
        const momentTransactionCreatedAt = moment(transactionCreatedAt, 'YYYY-MM-DDTHH:mm:ss');
        const startOfToday = moment().startOf('day');
        const endOfToday = moment().endOf('day');

        return (
            momentTransactionCreatedAt.isSameOrAfter(startOfToday) &&
            momentTransactionCreatedAt.isSameOrBefore(endOfToday)
        );
    }
    isTransactionCreatedWithinTimeframe(transactionCreatedAt: string): boolean {
        const momentTransactionCreatedAt = moment(transactionCreatedAt, 'YYYY-MM-DDTHH:mm:ss');
        const now = moment();
        const elevenAMYesterday = now
            .clone()
            .subtract(1, 'day')
            .set({ hour: 11, minute: 0, second: 0, millisecond: 0 });
        const elevenAMToday = now.clone().set({ hour: 11, minute: 0, second: 0, millisecond: 0 });
        if (now.isBefore(elevenAMToday)) return momentTransactionCreatedAt.isAfter(elevenAMYesterday);
        if (now.isAfter(elevenAMToday)) return momentTransactionCreatedAt.isAfter(elevenAMToday);
        return false;
    }
}
